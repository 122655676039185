.link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.thumbnail {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: var(--netflix-border-radius);
  object-fit: cover;
}

.info_container {
  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  padding: 7px 10px;
  font-size: 0.8vw;
}

.buttonContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 9px 0;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.5px;
  color: var(--netflix-white);
}

.topicList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.3em;
  line-height: 1.3em;
}

.topicItem {
  padding-right: 0.2vw;
}

.topicSeparator {
  padding-right: 0.5vw;
  font-size: 0.625em;
  color: var(--netflix-navigation);
  opacity: 0.5;
  transform: scale(30%);
}

.topicText {
  font-size: 0.5vw;
  color: var(--netflix-navigation);
  opacity: 0.9;
}

.hoveredLink,
.firstHoveredLink,
.lastHoveredLink {
  box-shadow: var(--apple-box-shadow);
  z-index: 1;
}

.hoveredLink {
  transform: scale(150%) translateY(-50%);
}

.firstHoveredLink {
  transform: scale(150%) translateY(-50%) translateX(calc(100% / 6));
}

.lastHoveredLink {
  transform: scale(150%) translateY(-50%) translateX(calc(-100% / 6));
}

.hoveredImg {
  border-radius: var(--netflix-border-radius) var(--netflix-border-radius) 0 0;
}

.hoveredInfo {
  background-color: var(--netflix-darkgray);
  border-radius: 0 0 var(--netflix-border-radius) var(--netflix-border-radius);
}

.hoveredTitle {
  font-weight: 700;
  margin-bottom: 9px;
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
}

.watching_text {
  display: block;
  width: 100%;
  margin-bottom: 1em;
  font-size: 0.4vw;
  font-weight: 300;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 2.4em;
  color: rgba(255, 255, 255, 0.8);
}

.strong {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
}

.progress_container {
  display: flex;
  align-items: center;
  margin-bottom: 1.3em;
}

.progress_hovered {
  height: 2px;
  flex-grow: 1;
}

.progress_text {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 0 8px;
  font-weight: 600;
  font-size: 0.9em;
}
