.header {
  margin-top: 7vw;
  margin-bottom: 6vw;
  padding: 0 4%;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
}
