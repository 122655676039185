.button {
  aspect-ratio: 1 / 1;
  margin-top: 0.2em;
  margin-bottom: auto;

  outline: none;
  border: none;
  cursor: pointer;
  background: transparent;
  color: var(--netflix-navigation);

  transition: transform 0.3s ease-in;
}

.active {
  transform: rotateZ(180deg);
}
