@keyframes loadinganim {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wrapper {
  background: transparent;
  min-height: 100px;
  color: var(--netflix-red);
  text-align: center;
  display: table;
  width: 100%;
  height: 100%;
}

.loading {
  display: table-cell;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  vertical-align: middle;
}

.bulletouter {
  animation: loadinganim 1s infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  width: 43px;
  height: 43px;
  background: var(--netflix-red);
  border-radius: 50%;
  margin: 0 auto;
}

.bulletinner {
  position: relative;
  left: -5px;
  width: 45px;
  height: 45px;
  background: var(--netflix-black);
  border-radius: 50%;
  margin: 0 auto;
}

.mask {
  position: relative;
  left: -5px;
  top: -15px;
  width: 50px;
  height: 25px;
  background: var(--netflix-black);
  transform: rotate(45deg);
}

.dot {
  position: relative;
  left: 33px;
  top: -32px;
  width: 8px;
  height: 8px;
  background: var(--netflix-red);
  border-radius: 50%;
}

.text {
  padding-top: 23px;
}
