.wrapper {
  margin-top: 68px;
  min-height: calc(100vh - 68px);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topic_list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topic_item {
  margin-top: 40px;
}

.topic_item a {
  padding: 10px 100px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3vw;
  line-height: 1.3;
  letter-spacing: 1px;
  transition: color 0.2s ease-in-out;
}

.topic_item a:hover {
  color: rgba(255, 255, 255, 1);
}
