@import "reset.css";

:root {
  --netflix-red: #e50914;
  --netflix-black: #141414;
  --netflix-white: #fff;
  --netflix-darkgray: #181818;
  --netflix-navigation: #e5e5e5;

  --netflix-border-radius: 0.2vw;
  --apple-box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  overflow-x: hidden;
  color: var(--netflix-white);
  background-color: var(--netflix-black);
}

a {
  text-decoration: none;
  color: var(--netflix-white);
}
