.progress_bar {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #717171;
}

.progress_completed {
  display: block;
  background-color: var(--netflix-red);
  height: 100%;
}
