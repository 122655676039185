:root {
  --slider-height: calc(
    92vw / 6 * 9 / 16 + 0.8vw + 14px
  ); /* thumbnail + infoContainer height */
}

.rowSlider {
  width: 100%;
  margin: 3vw 0;
}

/* .rowHeader {
  line-height: 1.3vw;
} */

.rowLink {
  display: inline-block;
  margin: 0 4% 0.5em 4%;
  font-size: 1.4vw;
  font-weight: 500;
  min-width: 6em;
  color: var(--netflix-navigation);
}

.rowLink:hover {
  color: var(--netflix-white);
}

.rowTitle {
  display: inline-block;
  vertical-align: bottom;
  font-size: 1.4vw;
  line-height: 1.3;
}

.arrow {
  display: inline-block;
  vertical-align: bottom;
}

.arrowText {
  display: inline-block;
  vertical-align: bottom;
  font-size: 0.9vw;
  margin-right: 4px;
  line-height: 1.4vw;
  opacity: 0;
  transition: opacity 1s, transform 0.75s;
}

.arrowIcon {
  display: inline-block;
  vertical-align: bottom;
  font-size: 1vw;
  line-height: 1.4vw;
  transform: translateX(-3.3vw);
  transition: transform 0.75s;
}

.arrowHovered {
  transform: translateX(1.2vw);
  max-width: unset;
  opacity: 1;
  font-size: 0.9vw;
}

.rowContainer {
  width: 100%;
}

.slider {
  position: relative;
  width: 100%;
  padding: 0 4%;
}

.sliderMask {
  position: relative;
  width: 100%;
  height: var(--slider-height);
}

.content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  white-space: nowrap;
  overflow: visible;
  transition: transform 0.7s ease-in-out;
}

.previewHovered {
  z-index: 2;
}

.item {
  display: inline-block;
  width: calc(100% / 6);
  aspect-ratio: 16 / 9;
  padding: 0 0.2vw;
}

.handlePrev,
.handleNext {
  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 3.8vw;
  height: var(--slider-height);

  color: var(--netflix-navigation);
  background-color: rgba(20, 20, 20, 0.5);
  font-size: 1.6vw;
  opacity: 0.8;
  z-index: 20;
  cursor: pointer;

  transition: all 0.1s ease-in-out;
}

.handlePrev {
  left: 0;
  border-radius: 0 var(--netflix-border-radius) var(--netflix-border-radius) 0;
}

.handleNext {
  right: 0;
  border-radius: var(--netflix-border-radius) 0 0 var(--netflix-border-radius);
}

.handlePrev:hover,
.handleNext:hover {
  background-color: rgba(20, 20, 20, 0.8);
  color: var(--netflix-white);
  font-size: 2.2vw;
}

.pagination_indicator {
  position: absolute;
  top: 0;
  right: 4%;
  margin: -24px 0 12px 0;
}

.pagination_indicator li {
  display: inline-block;
  width: 12px;
  height: 2px;
  margin-left: 1px;
  background-color: #4d4d4d;
}

.pagination_indicator .active {
  background-color: #aaa;
}
