.header {
  margin-top: 9vw;
  margin-bottom: 3vw;
  padding: 0 4%;
}

.title {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
}

.header_text {
  font-size: 0.9375vw;
  color: grey;
  margin-right: 5px;
  white-space: nowrap;
}

.main {
  min-height: 100vh;
}
