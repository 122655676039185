.loading_background {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

.background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: -1;
  opacity: 0.3;
  object-fit: cover;
}

.lecture_container {
  width: 850px;
  margin: 2em auto;
  margin-top: 85px;
  background-color: var(--netflix-darkgray);
  border-radius: 6px;
  box-shadow: var(--apple-box-shadow);
}

.lecture_preview {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.lecture_thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 0 0;
  object-fit: cover;
}

.lecture_previewWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#181818),
    color-stop(50%, transparent)
  );
  background: -webkit-linear-gradient(bottom, #181818, transparent 50%);
  background: linear-gradient(to top, #181818, transparent 50%);
}

.back_button {
  position: absolute;
  top: 3em;
  left: 3em;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;
  background-color: var(--netflix-darkgray);
  color: var(--netflix-white);

  border: none;
  border-radius: 50%;
  box-shadow: var(--apple-box-shadow);
  cursor: pointer;
}

.lecture_controls {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 15%;
  left: 3em;
  width: calc(92vw / 6);
  transform: scale(150%) translateX(calc(100% / 6));
}

.lecture_info {
  padding: 0 3em;
}

.lecture_metadata {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  column-gap: 2em;
}

.lecture_title {
  margin: 0.8em 0;
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 1px;
}

.lecture_synopsis {
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
}

.lecture_description {
  line-height: 27px;
  font-size: 16px;
  color: #d2d2d2;
}

.clamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.metadata_right {
  margin-top: 1em;
}

.tag {
  font-size: 14px;
  line-height: 20px;
  margin: 0.5em;
  margin-left: 0;
  word-break: break-word;
}

.tag_label {
  color: #777;
}

.tag_more,
.tag_item {
  color: #ddd;
  outline-color: var(--netflix-white);
}

.tag_more {
  font-style: italic;
}

.tag_more:hover,
.tag_item:not(.tag_item_notLink):hover {
  text-decoration: underline;
}

.video_selector {
  padding: 1em 0 0 0;
}

.selector_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.selector_label {
  font-weight: 700;
  font-size: 24px;
  margin: 48px 0 20px 0;
}

.selector_length {
  font-size: 18px;
}

.selector_container {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}

.section_devider {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 0;
  margin: auto;
  border-bottom: 2px solid #404040;
}

.divider_button {
  position: absolute;
  bottom: 0;
  transform: scale(150%) translateY(calc(-25% * 2 / 3 + 50%));
  color: var(--netflix-white);
}

.about_wrapper {
  padding-bottom: 2em;
}

.about_header {
  font-size: 24px;
  margin: 48px 0 20px 0;
  font-weight: 400;
  font-style: italic;
}

.about_header strong {
  font-weight: 600;
}
