.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  width: 100%;
  height: 68px;
  background-color: var(--netflix-black);

  padding: 0 4%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 21;

  transition: all 0.3s ease-in-out;
}

.transparent {
  background-color: transparent;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );
}

.header_column {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  font-size: 28px;
  font-weight: 700;
  color: var(--netflix-red);
  margin-right: 15px;
  letter-spacing: 1px;
}

.primary_navigation {
  display: flex;
  align-items: center;
}

.primary_navigation a {
  font-size: 14px;
  margin-left: 15px;
  color: var(--netflix-navigation);
}

.secondary_navigation {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--netflix-white);
}

.nav_element {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 24px;
}

.search_box {
  width: auto;
  margin-left: 100px;
  display: flex;
  align-items: center;
}

.search_box_active {
  background: rgba(0, 0, 0, 0.75);
  border: solid 1px rgba(255, 255, 255, 0.85);
}

.search_icon_column {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_icon,
.refresh_icon {
  padding: 1px 6px;
  font-size: 17px;
  cursor: pointer;
}

.search_icon_active {
  cursor: default;
}

.search_input {
  width: 212px;
  height: 34px;
  line-height: 34px;
  color: var(--netflix-white);
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  padding: 7px 14px 7px 7px;
  font-size: 14px;
}

.user_wrapper {
  height: 100%;
}

.user_container {
  position: relative;
  display: flex;
  align-items: center;
  height: 50%;
  cursor: pointer;
}

.user_alt_icon {
  font-size: 22px;
}

.user_thumbnail {
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: var(--netflix-border-radius);
}

.user_more {
  margin-left: 10px;
  transition: transform 0.2s ease-in;
}

.more_active {
  transform: rotateZ(180deg);
}

.user_menu_arrow {
  position: absolute;
  bottom: -12px;
  right: 42px;
  height: 0;
  width: 0;
}

.user_menu {
  position: absolute;
  right: 0;
  top: 58px;
  width: 181px;

  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: var(--netflix-white);
  font-size: 13px;
  line-height: 21px;
  cursor: default;
}

.user_menu_list {
  padding: 10px 0;
  border-top: solid 1px rgba(255, 255, 255, 0.25);
  cursor: default;
}

.user_menu_list:first-child {
  border-top: none;
}

.user_menu_item {
  padding: 5px 10px;
  display: block;
  font-size: 13px;
  line-height: 16px;
  cursor: default;
}

.user_menu_link {
  display: flex;
  align-items: center;
}

.user_menu_link:hover {
  text-decoration: underline;
}

.sign_out_link {
  display: block;
  width: 100%;
  text-align: center;
}

.user_menu_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #b3b3b3;
  margin: 0 13px 0 5px;
}
