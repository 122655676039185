.header {
  margin-top: 7vw;
  margin-bottom: 6vw;
  padding: 0 4%;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
}

.search_title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
}

.search_text {
  font-size: 0.9375vw;
  color: grey;
  margin-right: 5px;
  white-space: nowrap;
}
